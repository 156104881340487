.side-nav-filter-option {
  text-align: left;
  border-bottom: 1px solid #d0d7de;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.numberCircleUserCount {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  color: #ffffff;
  display: inline-block;
  /* font-weight: bold; */
  line-height: 40px;
  margin-right: 6px;
  height: 24px;
  text-align: center;
  min-width: 24px;
  padding: 6px 6px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: "14px";
}

.side-nav-filter-option:hover {
  background-color: #f5f8fa;
}

.side-nav-filter-option-joined-active {
  border-left: #0076A8 4px solid;
}

.side-nav-filter-option-requested-active {
  border-left: #0076A8 4px solid;
}

.side-nav-filter-option-rejected-active {
  border-left: #0076A8 4px solid;
}

.side-nav-filter-option-removeRequested-active {
  border-left: #0076A8 4px solid;
}

.side-nav-filter-option-removed-active {
  border-left: #0076A8 4px solid;
}

.side-nav-filter-option.active {
  background-color: #f5f8fa;
}

.side-nav-filter-header {
  text-align: center;
  border-bottom: 1px solid #d0d7de;
  padding: 10px;
}
