.MuiDialog-paper {
  max-height: calc(100% - 209px);
  max-width: 500px;
}

.MuiDataGrid-columnHeaderTitle {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: black !important;
}

.MuiDialogActions-root {
  justify-content: flex-start !important;
}

.MuiDataGrid-columnHeaders {
  border: none !important;
}

.MuiDataGrid-root {
  border: none !important;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1) !important;
}

.MuiDataGrid-root .MuiDataGrid-row {
  min-height: 40px !important;
  background: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 4px !important ;
  border-radius: 2px !important;
  margin-bottom: 3px !important;
}

.MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell {
  min-height: 40px !important;
}

.MuiDataGrid-footerContainer {
  border: none !important;
}

.MuiDivider-root {
  border: none !important;
}

.MuiButton-root.Mui-disabled {
  color: #9b9b9bb5 !important;
  box-shadow: none !important;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0.04) !important;
  font-weight: 400 !important;
  letter-spacing: 0.5px;
  padding: 4px 16px;
  border-radius: 2px;
  font-size: 14px;
}

.MuiTablePagination-root .MuiTablePagination-selectLabel {
  margin-bottom: 0rem !important;
}

.MuiTablePagination-displayedRows {
  margin-bottom: 0rem !important;
}

.requestButton {
  color: #ffffff !important;
  box-shadow: none !important;
  background-color: #008080 !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  text-transform: unset !important;
  min-width: 11.5em !important;
}

.requestButton:hover {
  background-color: #006d6d !important;
}

.MuiDataGrid-root .MuiDataGrid-columnSeparator {
  display: none !important;
}

.headerSearchFlex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 14px; */
}

.MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell {
  width: 0px !important;
}

.MuiOutlinedInput-root {
  /* height: 31px !important; */
}

.Mui-focused {
  color: #008080 !important;
}

.MuiOutlinedInput-input .Mui-focused :focus {
  outline: 1px solid #008080 !important;
}

.MuiDataGrid-main {
  margin-bottom: 1.5rem;
}

.MuiDataGrid-virtualScrollerContent {
  height: unset !important;
}

.MuiTablePagination-toolbar {
  display: none !important;
}

.active > .page-link,
.page-link.active {
  background-color: #53565a !important;
  border-color: #53565a !important;
}

.css-v0l51d-MuiDataGrid-footerContainer {
  display: none !important;
}
