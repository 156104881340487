.approveButton {
    
}

.approveButton:hover {
    cursor: pointer;
}

.rejectButton {
}

.rejectButton:hover {
    cursor: pointer;
}