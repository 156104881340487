.try-again-btn {
    color: #ffffff !important;
    background-color: #008080 !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    text-transform: unset !important;
    min-width: 11.5em !important;
    padding: 0.5em 1em !important;
    border-radius: 4px;
}

.try-again-btn:hover {
    color: #ffffff !important;
    background-color: #006666 !important;
}