.admin-dashboard-container {
  margin-bottom: 0px;
  margin-top: 20px;
  display: flex;
}

.dashboard-name {
  margin-left: 5px;
  font-size: 16px;
  font-weight: 500;
}

.admin-dashboard-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.admin-dashboard-header-content {
  font-size: 20px;
  font-weight: 400;
  padding-left: 0px;
  /* margin-top: 10px; */
}

.admin-dashboard-signup-banner-toggle {
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 14px;
  font-weight: 400;
  color: #1976d2;
}

.admin-dashboard-signup-banner-toggle:hover {
  cursor: pointer;
  color: #1976d2;
}

.admin-dashboard-search {
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.admin-dashboard-support-container {
  margin-top: 20px;
}

.request-support-link {
  color: rgb(0 151 215);
  font-weight: 500;
}

.alert-container {
  margin-top: 20px;
}

.table-cell-trucate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 30px;
  font-size: 12px;
}

.table-cell-trucate-reason {
  /* white-space: nowrap;
    overflow: hidden; */
  padding-left: 30px;
  font-size: 12px;
}

.table-cell-trucate-expand {
  white-space: nowrap;
  overflow: hidden;
  padding-left: 10px;
  font-size: 12px;
  color: blue;
}

.table-cell-trucate-expand:hover {
  cursor: pointer;
  text-decoration: underline;
}

.table-cell-trucate-email {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.orgName-column-header {
  padding-left: 40px !important;
}

.MuiDataGrid-cell {
  font-size: 14px !important;
}

.MuiStepLabel-label {
  font-size: 14px !important;
}

.instruction-icon {
  display: inline-block;
  cursor: pointer;
  padding: 0;
  border: none;
  background: none;
}

.approvalReq-Icon {
  color: #008080;
}

.instruction-icon-svg {
  color: #008080;
  height: 1.5em !important;
  width: 2rem !important;
}

.bullet {
  margin-bottom: 5px;
}

.dashboard-instructions {
  padding: 5px;
  font-size: 12px;
  font-family: "Open Sans" !important;
}

.dashboard-instructions-header {
  font-size: 12px;
  font-weight: bold;
}

.lock-icon {
  color: rgb(88, 88, 88);
}

.page-mask {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.603);
  z-index: 9999;
}

.loader {
  position: relative;
  top: 50%;
  left: 50%;
}

.multi-select-action-btn {
  margin-right: 5px;
  border-radius: 2px;
  transition: background-color 0.2s ease;
}

.multi-select-action-btn:hover {
  background-color: #d6d6d6;
  cursor: pointer;
}

.admin-headerSearchFlex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  /* margin-bottom: 0.5rem; */
}

.signup-banner-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease; /* Adjust the duration and easing function as needed */
}

.visible {
  max-height: 150px;
}

.hidden {
  max-height: 0;
}

.keyboard-down-icon {
  transition: transform 0.4s !important;
}

.rotated {
  transform: rotate(180deg);
}
