.welcome-text {
  font-size: 40px;
}

.welcome-container-center {
  text-align: center !important;
  padding-top: 20vh !important;
}

.login-button {
  border-radius: 2px !important;
  width: 14rem rem !important;
  text-transform: unset !important;
  border-radius: 4px !important;
  border: 1px solid #fff !important;
  background: #d9d9d9 !important;
  color: #292929 !important;
}

.welcome-text {
  margin-left: auto;
  margin-right: auto;
  color: hsl(0, 0%, 56%);
  font-weight: 300 !important;
  font-family: "Open Sans" !important;
  letter-spacing: 1px !important;
  overflow: hidden;
  border-right: 2px solid hsl(0, 0%, 80%);
  white-space: nowrap;
  animation: typewriter 3s steps(44) 1s 1 normal both,
    blinkTextCursor 1500ms infinite;
}

.homePageContainer {
  padding: 0px !important;
  overflow: hidden;
}

.loginContainer {
  display: flex !important;
  height: 100vh !important;
  flex-direction: row !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  overflow: hidden !important;
  margin: none !important;
}
.label-button-container {
  gap: 12px;

  display: flex;
  flex-direction: column;
}

.welcomeText {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  line-height: 25px;
  width: 12em;
  color: #ffffff;
}

.loginPart {
  background-color: #008080;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 30%;
}

.backgroundPicturePart {
  display: flex;
  width: 70%;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  /* background-position: center; */
  background-color: black;
  /* background-image: url(./BackgroundECREMULOGIN.png); */
  background-size: contain;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinningTest {
  animation: loading 110s linear infinite;
  height: 39rem;
}

.sign-in-btn-home {
  text-align: -webkit-center;
}

.sign-in-btn-home button {
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
}

.sign-in-btn-home button {
  --primary-color: hsl(0, 0%, 56%);
  --hovered-color: hsl(81deg 97% 41%);
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 20px;
  gap: 0.5rem;
  align-items: center;
}

.sign-in-btn-home button p {
  margin: 0;
  position: relative;
  font-size: 25px;
  color: var(--primary-color);
}

.sign-in-btn-home button::after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  bottom: -7px;
  background: var(--hovered-color);
  height: 2px;
  transition: 0.3s ease-out;
}

.sign-in-btn-home button p::before {
  position: absolute;
  /*   box-sizing: border-box; */
  content: "Login";
  width: 0%;
  inset: 0;
  color: var(--hovered-color);
  overflow: hidden;
  transition: 0.3s ease-out;
}

.sign-in-btn-home button:hover::after {
  width: 100%;
}

.sign-in-btn-home button:hover p::before {
  width: 100%;
}

.sign-in-btn-home button:hover svg {
  transform: translateX(4px);
  color: var(--hovered-color);
}

.sign-in-btn-home button svg {
  color: var(--primary-color);
  transition: 0.2s;
  position: relative;
  width: 15px;
  transition-delay: 0.2s;
}
