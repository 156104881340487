.expand-btn {
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: rgb(95, 33, 32)
}

.expand-btn:hover {
    color: rgb(95, 33, 32);
    font-weight: bold;
    text-decoration: none;
}

.keyboard-down-icon {
    transition: transform 0.4s !important;
}
  
.rotated {
    transform: rotate(180deg);
}

.expandable-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease; /* Adjust the duration and easing function as needed */
}
  
.expandable-content-visible {
    max-height: 200px;
}
  
.expandable-content-hidden {
    max-height: 0;
}