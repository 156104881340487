/* .MuiDataGrid-columnHeaderTitle {
  font-size: 14px !important;
  font-weight: 500 !important;
} */

/* .MuiDataGrid-root .MuiDataGrid-row {
  min-height: 60px !important;
} */

.revoke-access-button {
  background-color: #ffffff;
  border: 0px;
  border-radius: 4px;
  color: rgb(0, 0, 0);
  padding: 6px 12px;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid black;
}

.revoke-access-button:disabled {
  background-color: #eeeeeeed;
  border: 0px;
  border-radius: 4px;
  color: rgb(135, 135, 135);
  padding: 6px 12px;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid rgb(199, 199, 199);
  box-shadow: unset;
}

.revoke-access-button:disabled:hover {
  background-color: #eeeeeeed;
  border: 0px;
  border-radius: 4px;
  color: rgb(135, 135, 135);
  padding: 6px 12px;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid rgb(199, 199, 199);
  box-shadow: unset;
}

.joined:hover {
  background-color: #DA291C;
  /* border: 0px; */
  border-radius: 4px;
  color: white;
  padding: 6px 12px;
  font-size: 12px;
  border: 1px solid #DA291C;
}

.rejected:hover {
  background-color: #86BC25;
  border-radius: 4px;
  color: white;
  padding: 6px 12px;
  font-size: 12px;
  border: 1px solid #86BC25;
}

.removed:hover {
  background-color: #86BC25;
  border-radius: 4px;
  color: white;
  padding: 6px 12px;
  font-size: 12px;
  border: 1px solid #86BC25;
}

.table-cell-history {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 50px;
  font-size: 13px !important;
}
