.legend-container {
  display: flex;
  /* box-shadow: rgb(0 0 0 / 10%) 2px -1px 4px !important; */
  /* grid-template-columns: repeat(2, 2fr); */
  padding: 10px 5px 10px 30px;
  justify-content: center;
  border-radius: 4px;
}

.legend-entry-container {
  /* width: 100%; */
  align-items: center;
  display: flex;
  justify-content: left;
  font-size: 0.8rem;
  margin-right: 20px;
}

.p-9 {
  padding: 0 9px;
}

.legend-hr {
  margin-left: 0;
  margin-right: 0;
  border-radius: 3px !important;
  opacity: 100 !important;
}
