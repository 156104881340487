.github-signIn-link {
    color: white;
    margin-right: 2em;
    text-decoration: none;
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'Open Sans' !important;
}

.github-signIn-link:hover {
    color: white;
    font-weight: bold;
}