.viewRequestButton {
  color: #008080 !important;
  box-shadow: none !important;
  border-color: #008080 !important;
  background-color: #ffffff !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  text-transform: unset !important;
}

.viewRequestButton:hover {
  background-color: #006666 !important;
  color: #ffffff !important;
}

.make-request-btn {
  color: #ffffff !important;
  box-shadow: none !important;
  /* border-color: #008080 !important; */
  background-color: #008080 !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  text-transform: unset !important;
  min-width: 11.5em !important;
}

.make-request-btn:hover {
  background-color: #006666 !important;
  color: #ffffff !important;
}

.make-request-btn:disabled {
  color: #a5a5a5b5 !important;
  border: 1px solid rgb(236, 236, 236) !important;
  box-shadow: none !important;
  text-transform: unset !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
  font-weight: 400 !important;
  padding: 5px;
  letter-spacing: 0.5px;
  font-size: 12px !important;
}

.thickerLoader {
  stroke-width: 4
}