.table-cell-trucate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 30px;
  font-size: 12px;
}

.table-cell-home-OrgDisplayName {
  padding-left: 30px;
  flex-direction: column;
  padding-top: 5px;
  font-size: 12px;
  display: flex;
  align-items: flex-start;
  gap: 2px;
}

.table-cell-orgName {
  margin: 0px !important;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
  text-overflow: ellipsis;
}
.orgName-column-header {
  padding-left: 40px !important;
}
.MuiDataGrid-cell {
  font-size: 14px !important;
}
.MuiStepLabel-label {
  font-size: 14px !important;
}
.welcome-message {
  /* display: flex;
  align-items: center; */
  max-width: 100%;
  margin-bottom: 1.2rem;
  margin-left: 2px;
}
.circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  font-size: 8px;
  color: #ff0000;
  border: 2px solid #ff0000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-tooltip {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #ff0000;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 16px;
  position: relative;
}
.welcome-message span {
  font-size: 14px;
  font-weight: 500;
}

.instruction-icon {
  display: inline-block;
  cursor: pointer;
  padding: 0;
  border: none;
  background: none;
}
.approvalReq-Icon {
  color: #008080;
}
.instruction-icon-svg {
  color: #008080;
  height: 1.5em !important;
  width: 2rem !important;
}
/* .instruction-icon-svg:hover {
  color: #015858;
  height: 1.5em !important;
  width: 2.3rem !important;
  transition: 1s ease;
} */
.bullet {
  margin-bottom: 5px;
}
.dashboard-instructions {
  padding: 5px;
  font-size: 12px;
  font-family: "Open Sans" !important;
}
.dashboard-instructions-header {
  font-size: 12px;
  font-weight: bold;
}
