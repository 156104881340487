.admin-nav-links:hover {
  color: white;
}

.inner-nav-link {
  padding: 0.4rem 1rem;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 0.5rem;
  transition: 0.15s;
}

/* .admin-nav-links:hover .inner-nav-link {
  background-color: rgba(134, 188, 34, 0.3);
  color: white;
  border-radius: 4px;
} */

.admin-sub-links {
  padding: 4px 0 4px 53px;
  display: flex;
  align-items: center;
  font-size: 13px;
}

.admin-links-active {
  /* background-color: rgba(134, 188, 34, 0.3) !important; */
  border-left: rgba(134, 188, 34) solid 3px;
}

.sidebar-container {
  position: relative;
  width: 78px;
  height: 100vh;
  /* transition: all 0.5s ease; */
}

.sidebar-container.open {
  width: 275px !important;
}

.sidebar {
  position: relative;
  left: 0;
  top: 60;
  height: 100%;
  width: 78px;
  background: black;
  padding: 6px 14px;
  z-index: 99;
  height: 100vh;
  /* transition: all 0.5s ease; */
}
.sidebar.open {
  width: 275px;
}
.sidebar .logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
  white-space: nowrap;
}
.sidebar .logo-details .icon {
  opacity: 0;
  /* transition: all 0.5s ease; */
}
.sidebar .logo-details .logo_icon {
  opacity: 0;
  height: unset;
  min-width: 40px;
}
.sidebar.open .logo-details .logo_icon {
  opacity: 1;
}
.sidebar .logo-details .logo_name {
  color: #fff;
  font-size: 18px;
  font-weight: 200;
  opacity: 0;
  margin-top: 4px;
  /* transition: all 0.4s ease; */
  /* padding-left: 20px; */
}
.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
}
.sidebar .logo-details #btn {
  position: absolute;
  top: 54%;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  /* transition: all 0.4s ease; */
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.5s ease;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar.open .logo-details #btn {
  text-align: right;
}
.sidebar .logo-details #btn:hover {
  background-color: rgba(134, 188, 34, 0.3);
}
.sidebar i {
  color: #fff;
  height: 50px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}
.sidebar .nav-list {
  margin-top: 10px;
  height: 100%;
  padding-left: 0;
}
.sidebar li {
  position: relative;
  margin: 8px 0;
  list-style: none;
}
.sidebar li .tooltip {
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}
.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}
.sidebar.open li .tooltip {
  display: none;
}
.sidebar .logo-details .tooltip {
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}
.sidebar .logo-details:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}
.sidebar.open .logo-details .tooltip {
  display: none;
}
.sidebar input {
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  /* transition: all 0.5s ease; */
  background: black;
}
.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}
.sidebar li a {
  display: flex;
  height: 100%;
  width: 100%;
  /* border-radius: 12px; */
  align-items: center;
  text-decoration: none;
  /* transition: all 0.3s ease; */
  background: black;
}
.sidebar li a:hover {
  background: rgba(134, 188, 34, 0.3);
  /* border-radius: 12px; */
}
.sidebar li a .links_name {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  /* transition: 0.4s; */
}
.sidebar.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
}
.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  /* transition: all 0.5s ease; */
  
}
.sidebar li a .links_notifications {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  color: #fff;
  font-size: 10.5px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 1;
  position: absolute;
  pointer-events: none;
  /* transition: 0.4s ease-in-out; */
  right: 6px;
  bottom: 46%;
  background-color: rgba(134, 188, 34, 0.8);
  padding: 2px 4px 2px 4px;
  min-width: 20px;
  min-height: 20px;
  text-align: center;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.sidebar.open li a .links_notifications {
  opacity: 1;
  right: 10px;
  bottom: unset;
  font-size: 12.5px;
  pointer-events: auto;
  padding: 3px 3px 3px 4px;
  /* font-weight: normal; */
  min-width: 25px;
  background-color: rgba(134, 188, 34, 0.65);
}
.sidebar.open li .sub-menu-icon {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  color: #fff;
  font-size: 10.5px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 1;
  position: absolute;
  pointer-events: none;
  /* transition: 0.4s ease-in-out; */
  right: 6px;
  /* bottom: 46%; */
  padding: 2px 5px 2px 4px;
  min-width: 20px;
  min-height: 20px;
  text-align: center;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.sidebar.open li .sub-menu-icon:hover {
  background-color: red;
  cursor: pointer;
}
.sidebar li i {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}

@media (max-width: 420px) {
  .sidebar li .tooltip {
    display: none;
  }
}

