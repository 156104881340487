.MuiDataGrid-columnHeaderRow .rejected-tab-data-grid {
  background-color: black;
}

.table-history-DateRejected-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px !important;
  font-size: 13px !important;
}

.table-history-userEmailRejected-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px !important;
}

.table-history-userEmailJoined-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.date-rejected-table-header {
  padding-left: 1px !important;
}

.history-tab-totalCount-header {
  padding-left: 18px !important;
}

.history-tab-totalCount-header-approver-view {
  padding-left: 48px !important;
}

.date-joined-table-header {
  padding-left: 1px !important;
}

.history-tab-joinedDate-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 13px !important;
}
